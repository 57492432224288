<template>
  <nav class="navbar" :class="{'-sticky': sticky, '-open': open, '-important': isOpen}">
    <div class="navbar__wrap">
      <section class="navbar__section -grow">
        <RouterLink :to="{name:'home'}" class="navbar__logo" :class="{'-white': !sticky}">
          <Icon id="logo"/>
        </RouterLink>

        <div class="navbar__links">
          <RouterLink v-for="region in regions" :to="{name:'region', params: {region: region}}" class="navbar__link" :class="{'-active' : $route.params.region === region}" :key="region">
            {{ region }}
          </RouterLink>
        </div>

      </section>

      <section class="navbar__section navbar__section--contact">
        <a href="https://www.instagram.com/lesfilles_et_lessciences/" target="_blank">
          <Icon id="instagram"/>
        </a>
        <a :href="$beet.options.contact?.facebook?.link || ''" target="_blank">
          <Icon id="facebook"/>
        </a>
        <button class="navbar__contact" :class="{'-white': !sticky}" @click="scrollBottom">
          CONTACTEZ-NOUS
        </button>

        <Icon id="contact" class="navbar__contact-icon"></Icon>
      </section>

      <div class="navbar__burger" :class="{'-sticky': sticky}" @click="burger()">
        <transition name="fade-fast" mode="out-in">
          <Icon :id="open ? 'close' : 'burger'" :key="`open-${open}`"/>
        </transition>
      </div>
    </div>

    <div v-if="open" class="navbar__open">
      <div class="navbar__open-regions">
        <router-link v-for="city in $beet.cities" :key="city.id" :to="{name: 'region', params: {region: city.name}}"
                     class="navbar__open-region" :style="{backgroundColor: city.bgColor}" @click="burger">
          {{ city.name }}
        </router-link>
      </div>

      <div class="navbar__open-content">
        <div class="navbar__section navbar__section--contact -display">
          <button class="navbar__contact" @click="scrollBottom">
            CONTACTE-NOUS
          </button>
          <Icon id="contact" class="navbar__contact-icon"></Icon>
        </div>

        <a class="navbar__open-fb" :href="$beet.options.contact?.facebook?.link || ''" target="_blank">
          <p> {{ $beet.options.contact?.facebook?.title }} </p>
          <a class="navbar__open-fb" href="https://www.instagram.com/lesfilles_et_lessciences/" target="_blank">
            <Icon id="instagram"/>
          </a>
          <Icon id="facebook"/>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
name: "SiteNav",
  props: {
    sticky: {
      type: Boolean,
      default: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open : false
    }
  },
  watch: {
    isOpen(to) {
      this.open = to
    }
  },
  computed: {
    regions() {return ['Québec', 'Montréal', 'Sherbrooke', 'Rimouski']
    },
    cities() {
      return this.$beet.cities || []
    }
  },
  methods: {
    burger() {
      if(this.sticky) {
        if(this.isOpen) this.$emit('close')
        this.open = !this.open
      } else {
        this.$emit('burger')
      }
    },
    scrollBottom() {
      if(this.open) this.open = false
      const el = document.getElementsByClassName('footer')[0]
      el.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }
}
</script>
