import { createStore } from 'vuex'
import http from '@/extensions/http'
export default createStore({
  state: {

  },
  getters: {
    regions() {
      return (mq) => [
        {
          id: 3,
          name: 'Sherbrooke',
          color: '#EF9030',
          bgColor: '#020A35',
          formColor: '#EDEEF6',
          image: '/images/espace.svg',
          time: '8h30 à 16h00',
          price: 'Activité gratuite, inscription obligatoire',
          place: 'Pavillon Vachon de l’Université Laval',
          by: 'par Technoscience Estrie'
        },
        {
          id: 1,
          name: 'Québec',
          color: '#8EC449',
          bgColor: '#719E39',
          formColor: '#E8EDE3',
          image: mq.desktop ? '/images/physique.svg' : '/images/physique-sm.svg',
          time: '8h30 à 16h00',
          place: 'Pavillon Vachon de l’Université Laval',
          price: 'Activité gratuite, inscription obligatoire',
          by: 'par Technoscience Estrie'
        },
        {
          id: 4,
          name: 'Rimouski',
          color: '#2E328F',
          bgColor: '#13798F',
          formColor: '#E8F1F6',
          image: '/images/maritime.svg',
          time: '8h30 à 16h00',
          place: 'Pavillon Vachon de l’Université Laval',
          price: 'Activité gratuite, inscription obligatoire',
          by: 'par Technoscience Est-du-Québec'
        },
        {
          id:2,
          name: 'Montréal',
          color: '#F49330',
          bgColor: '#EE5A34',
          formColor: '#F8EDEB',
          image: '/images/fabrique.svg',
          time: '8h30 à 16h00',
          place: 'Pavillon Vachon de l’Université Laval',
          price: 'Activité gratuite, inscription obligatoire',
          by: 'par Technoscience Estrie'
        },
      ]
    }
  },
  mutations: {
  },
  actions: {
    submitForm(context, {city_id, form_id, fields}) {
          return new Promise((resolve, reject) => {
            http.post('/inscription', {city_id, form_id, fields}).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error.response.data)
            })
          })
    }
  },
  modules: {
  }
})
