<template>
  <router-view v-if="$beet.isReady" @burger="navOpen = !navOpen" />
  <SiteFooter v-if="$beet.isReady"/>
  <SiteNav :is-open="navOpen" @close="navOpen = false" v-if="$beet.isReady"/>
  <Gdpr/>
</template>

<script>
import SiteNav from "@/components/SiteNav";
import {useLangRouter} from "@/extensions/langRouter";
import SiteFooter from "@/components/SiteFooter.vue";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  setup() {
    useLangRouter()
  },
  components: {Gdpr, SiteFooter, SiteNav },
  data() {
    return {
      navOpen: false
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function(to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    },
    $route: {
      handler(to, from = {}) {
        const delay = to.name !== from.name ? 100 : 50
        setTimeout(() => {
          const el = to.hash ? document.querySelector(to.hash) : null
          el ?
            el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
            document.getElementsByClassName('header')[0]?.scrollIntoView({behavior: 'smooth', block: 'center'})
        }, delay)
      },
      immediate: true
    }
  }
}
</script>
