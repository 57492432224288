<template>
  <div class="footer-news">
    <div class="footer-news__content">
      <h2 class="footer-news__title" v-reveal> {{ content.title }} </h2>
      <p class="footer-news__text" v-reveal="{delay: 200}"> {{ content.subtitle }} </p>

      <div class="row -wrap -desktop">
        <button class="footer-news__button" v-reveal="{delay: 250}">
          <Icon id="instagram"/>
          <a href="https://www.instagram.com/lesfilles_et_lessciences/" target="_blank" class="footer-news__button-text"> {{ 'SUIS NOTRE PAGE INSTAGRAM' }} </a>
        </button>

        <button class="footer-news__button" v-reveal="{delay: 250}">
          <Icon id="facebook"/>
          <a :href="content.fbLink" target="_blank" class="footer-news__button-text"> {{ content.buttonFb }} </a>
        </button>
      </div>

    </div>
  </div>

  <footer class="footer">
    <div class="footer__hydro">
      <b> Évènement propulsé par </b>
      <img src="/images/HydroQuebec.svg" alt="Hydro-Québec" width="200" height="100"/>

      <router-link :to="{name: 'press'}" class="footer__info-press">
        {{ content.media.link }}
        <Icon id="arrow"/>
      </router-link>
    </div>

    <div class="footer__info">
      <h4 class="footer__info-title"> {{ content.media.title }} </h4>
      <p> {{ content.media.contact }} </p>
      <a :href="`mailto:${content.media.email}`" class="footer__info-mail">
        <Icon id="mail"/>
        {{ content.media.email }}
      </a>

      <h4 class="footer__info-title"> {{ 'Informations générales' }} </h4>
      <a :href="`mailto:${'info@lesfillesetlessciences.ca'}`" class="footer__region-info -no-margin">
        <Icon id="mail"/>
        {{ 'info@lesfillesetlessciences.ca' }}
      </a>

    </div>

    <div class="footer__regions">
      <div v-for="(region, key) in regions" :key="key" class="footer__region">
        <h4 class="footer__region-tag" :style="{backgroundColor: region.color}"> {{ region.title }} </h4>
        <p class="footer__region-name" > {{ region.contact }} </p>
        <a class="footer__region-info" :href="`mailto:${region.email}`" v-if="region.email">
          <Icon id="mail" :style="{color: region.color}"/>
          {{ region.email }}
        </a>
        <a class="footer__region-info" :href="`tel:${region.phone}`" v-if="region.phone">
          <Icon id="phone" :style="{color: region.color}"/>
          {{ region.phone }}
        </a>
      </div>
    </div>
  </footer>

  <div class="footer__notes">
    <div> © Les Filles et les Sciences Un Duo Électrisant 2023 | <RouterLink :to="{name:'policy'}" class="footer__notes-policy">Politique de confidentialité</RouterLink></div>
    <a href="https://boitebeet.com/" target="_blank" alt="Boite Beet"> Site conçu et développé par Beet </a>
  </div>
</template>

<script>
export default {
  name: "SiteFooter",
  computed: {
    content() {
      return {
        title: this.$beet.options.contact?.facebook?.title || '',
        subtitle: this.$beet.options.contact?.facebook?.subtitle || '',
        buttonFb: this.$beet.options.contact?.facebook?.button || '',
        fbLink: this.$beet.options.contact?.facebook?.link || '',
        media: {
          title: this.$beet.options.contact?.media?.demand || '',
          contact: this.$beet.options.contact?.media?.contactName || '',
          email: this.$beet.options.contact?.media?.contactEmail || '',
          link: this.$beet.options.contact?.press?.linkText || ''
        }
      }
    },
    regions() {
      const regions = []
      if(this.$beet.cities) {
        Object.values(this.$beet.cities).forEach(city => {
          regions.push({
            title: city.name,
            contact: city.contactName,
            email: city.contactEmail,
            phone: city.contactPhone,
            color: city.bgColor
          })
        })
      }
      return regions
    }
  },
  data() {
    return {
      title: 'SOIS LA PREMIÈRE AU COURANT DES NOUVEAUTÉS',
      text: 'Découvre les ambassadeurs de l’édition 2022 et viens y voir les photos et les vidéos des éditions précédentes pour te donner une idée du plaisir que tu auras si tu t’inscris!',
      buttonFb: 'SUIS NOTRE PAGE FACEBOOK',
      media: {
        title: 'Pour toute demande média',
        contact: 'Émilie Marsolais',
        email: 'e_marsolais@videotron.ca',
        link: 'CONSULTE LE COMMUNIQUÉ DE PRESSE'
      }
    }
  }
}
</script>
